import { CMSCroppedImage } from 'components/images/Image';
import TicketButton from 'components/TicketButton';
import { FwPakkeImageDocument, ICultureArticle } from 'lib/types/sanity';
import Show, { GroupedShow } from 'lib/types/Show';
import Link from 'next/link';
import styled from 'styled-components';

//#region [Styles]
const SCultureShow = styled.article`
`;

const SImageContainer = styled.a`
	position: relative;
	display: block;
	box-sizing: content-box;
	aspect-ratio: 16 / 9;
	background-color: #fefefe;
	border-bottom: 7px solid var(--color_posters_border, var(--color_primary));
`;

const SImageMissing = styled.div`
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
	color: black;
	&::before {
		display: block;
		text-align: center;
		content: "Bilde mangler";
	}
`;

const SCultureImage = styled(CMSCroppedImage)`
	display: block;
	width: 100%;
	height: auto;
`;

const STextContainer = styled.div`
    flex: 1 1 auto;
    padding-bottom: 5px;
`;

const STitleContainer = styled.div`
	position: relative;
	margin: 0;
	padding: 15px 5px 0 15px;
	background-color: var(--color_fpposter_bg_mobile_culture, #fefefe);


	display: flex;
	gap: 15px;
	align-items: flex-start;

	@media screen and (min-width: 641px ) {
		background-color: var(--color_fpposter_bg_culture, #fefefe);
	}
	h1 {
		margin: 0;
		font-size: 1rem;
		padding-bottom: 5px;
		a {
			color: var(--color_fpposter_text_mobile_culture, #000000);
			@media screen and (min-width: 641px ) {
				color: var(--color_fpposter_text_culture, #000000);
			}
		}

	}
`;

const SMoreShowsLink = styled(Link)`
    text-decoration: underline;
    color: var(--color_primary);
    margin-bottom: 15px;
`;

const STicketButton = styled(TicketButton)`
	flex: 0 0 160px;
	position: relative;
	margin-top: -35px;
	margin-bottom: -7px;
`;

//#endregion

//#region [Props]
type OneCultureShowProps = {
    show: Show | GroupedShow;
    article?: ICultureArticle;
    className?: string;

};
//#endregion

//#region [Component]
export default function OneCultureShow({ show, article, className }: OneCultureShowProps) {
    const title = article?.titleOverride ?? show.movieTitle;
    const url = article ? `/kulturprogram/${show.movieVersionId}` : show.ticketSaleUrl;
    const tgt = article ? undefined : "_blank";
    return <SCultureShow className={className}>
        <Link href={url} passHref legacyBehavior>
            <SImageContainer target={tgt}>
                {article?.image && <SCultureImage image={article.image as FwPakkeImageDocument} width={470} heightRatio={0.5625} sizes="(min-width: 641px) 470px, 100vw" />}
                {!article?.image && <SImageMissing />}
            </SImageContainer>
        </Link>
        <STitleContainer>
            <STextContainer>
                <h1><Link href={url} target={tgt}>{title}</Link></h1>
                {isGroupedShow(show) && show.totalNumShows > 1 &&
                    <SMoreShowsLink href={url} target={tgt}>Vis {show.totalNumShows} forestillinger</SMoreShowsLink>}
            </STextContainer>
            <STicketButton show={show} showDate showPassed />
        </STitleContainer>
    </SCultureShow>;
}

function isGroupedShow(show: Show | GroupedShow): show is GroupedShow {
    return (show as GroupedShow).totalNumShows !== undefined;
}

//#endregion