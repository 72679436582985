import { Filmomtale } from "@filmweb/sanity-types";
import ArticleItem from "components/frontpage/otherarticles/ArticleItem";
import { DocumentType_MOVIE, IMovieArticle, INewsArticle, ISpecialShowArticle } from "lib/types/sanity";
import styled from "styled-components";


//#region [Styles]
const SOtherArtsContainer = styled.section`
	margin-bottom: var(--spacer_size);
	@media screen and (max-width: 640px) {
		padding: 10px 10px 0.1px 10px;
		background-color: var(--color_section_bg_mobile, transparent);
		>h1 {
			color: var(--color_section_text_mobile, var(--color_text, #000));
		}
	}

	@media screen and (min-width: 641px) {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
		justify-content: center;
	}
`;
//#endregion

//#region [Props]
type OtherArticlesProps = {
	articleList?: (ISpecialShowArticle | INewsArticle | IMovieArticle)[];
	fwFilms?: Filmomtale[];
	allowTitleOverride?: boolean;
};
//#endregion

//#region [Component]
export default function OtherArticles({ articleList, fwFilms, allowTitleOverride = false }: OtherArticlesProps) {
	if ((articleList?.length ?? 0) === 0) {
		return null;
	}

	const artList = articleList?.filter(art => !!art?._id);

	return <SOtherArtsContainer>
		{artList!.map(article => {
			let movie: Filmomtale | undefined;
			if (article._type === DocumentType_MOVIE) {
				const edi = (article as IMovieArticle).edi.current;
				movie = fwFilms?.find(film => film.mainVersionEDI === edi);
			}
			return <ArticleItem key={article._id} article={article} film={movie} allowTitleOverride={allowTitleOverride}/>;
		})}
	</SOtherArtsContainer>
}
//#endregion

/////////////////////////////////////////////////////////////////////////////////////////////////////////
