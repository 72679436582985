import { useQuery } from "@tanstack/react-query";
import PosterShowsDisplay from "components/frontpage/cinemaprogram/PosterShowsDisplay";
import { getProgram } from "lib/client/movieinfo";
import IProgram from "lib/types/Program";
import Settings from "lib/types/Settings";

//#region [Props]
type ShowsTodayProps = {
	settings: Settings;
}
//#endregion

//#region [Component]
export default function ShowsToday({ settings }: ShowsTodayProps) {
	const date = new Date();
	date.setHours(0, 0, 0, 0);

	const { isLoading, isError, data, error } = useQuery({
		queryKey: ["program-showstoday", date.toDateString()], // key
		queryFn: () => getProgram(undefined, date, true), // loader function
		refetchOnWindowFocus: false,
		refetchInterval: 600000, // refretch automatically every 10 minutes
		staleTime: 599999,
	} // options
	);

	return <PosterShowsDisplay heading="På kino idag" settings={settings} data={data as IProgram} error={error} isError={isError} isLoading={isLoading} />
}
//#endregion