import TrailerIconSVG from "@/public/TrailerButtonMark1.svg";
import { ICustomImage } from "@filmweb/sanity-types";
import SSectionHeader from "components/framework/SSectionHeader";
import { CMSCroppedImage } from "components/images/Image";
import { toRem } from "lib/client/fonts";
import { FilmwebFrontpageContent, FilmwebNews, FilmwebTrailer } from "lib/server/sanity";
import { PageElement } from "lib/types/Settings";
import Link from "next/link";
import { ReactNode } from "react";
import styled from "styled-components";


//#region [Styles]
const SFilmwebSection = styled.section`
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 10px 10px 10px 10px;
	margin-bottom: 30px;

	@media screen and (max-width: 640px) {
		background-color: var(--color_section_bg_mobile, transparent);
		>h1 {
			color: var(--color_section_text_mobile, var(--color_text, #000));
		}
	}

	@media screen and (min-width: 641px){
		margin-bottom: 50px;
		display: grid;
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
	}
`;

const SFilmwebSectionHeader = styled(SSectionHeader)`
	--color_local_border_override: var(--color_filmweb_sectiontitle);
	margin: 0 auto;
	width: 100%;
	grid-column: 1 / 4;
`;
//#endregion


//#region [Props]
type FilmwebContentSectionProps = {
	filmwebElements?: PageElement[];
	content?: FilmwebFrontpageContent;
};
//#endregion

//#region [Component]
export default function FilmwebContentSection({ filmwebElements, content }: FilmwebContentSectionProps) {
	if (!filmwebElements || filmwebElements.length === 0) {
		return null;
	}

	return (<SFilmwebSection>
		{filmwebElements.length === 2 && <SFilmwebSectionHeader>Trailere og filmnytt fra Filmweb</SFilmwebSectionHeader>}
		{filmwebElements.length === 1 && <>
			{filmwebElements[0].elementType === "trailers" && <SFilmwebSectionHeader>Trailere fra Filmweb</SFilmwebSectionHeader>}
			{filmwebElements[0].elementType === "news" && <SFilmwebSectionHeader>Filmnytt fra Filmweb</SFilmwebSectionHeader>}
		</>}

		{filmwebElements?.map((element, elementIndex) => {
			let frontpageElement: ReactNode | undefined;
			switch (element.elementType) {
				case "trailers":
					frontpageElement = content?.trailers?.slice(0, 3)?.map(trailer => <FilmwebArticle key={trailer._id} article={trailer} />);
					break;
				case "news":
					frontpageElement = content?.news?.slice(0, 3)?.map(news => <FilmwebArticle key={news._id} article={news} />);
					break;
			}

			return frontpageElement;
		})}
	</SFilmwebSection>);
}
//#endregion


//#region [Styles]
const SFilmwebArticle = styled.article`
	background-color: var(--color_fwelement_bg_mobile, var(--color_fwelement_bg, #fefefe));
	color: var(--color_fwelement_text_mobile, var(--color_fwelement_text, #000000));
	text-align: center;

	@media screen and (min-width: 641px) {
		background-color: var(--color_fwelement_bg, #fefefe);
		color: var(--color_fwelement_text, #000000);
	}
`;


const SArticleTitle = styled.h1`
	font-size: ${toRem(21)};
	font-weight: normal;
	display: inline-block;
	text-align: left;
	margin: 0.5em auto;
	padding: 0 0.5em;

	@media screen and (min-width: 641px) {
		font-size: ${toRem(20)};
	}
`;

const SArticleLink = styled.a`
	display: block;
	height: 100%;
	color: currentColor;
`;

const SImageContainer = styled.div`
	aspect-ratio: 370 / 185;
	overflow: hidden;
	position: relative;

	&:empty {

		background-color: #cacaca;

		&::after {
			content: "Bilde mangler";
			position: absolute;
			top: 50%;
			width: 100%;
			left: 0;
			text-align: center;
			transform: translateY(-50%);
		}
	}

	svg {
		display: block;
		width: 30%;
		aspect-ratio: 1 / 1;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		//color: var(--color_fwelement_bg_mobile, var(--color_fwelement_bg, #fefefe));
		color: var(--color_fwelement_bg, #fefefe);

		@media screen and (min-width: 641px) {
			color: var(--color_fwelement_bg, #fefefe);
		}
	}

`;

const SArticleImage = styled(CMSCroppedImage)`
	display: block;
	width: 100% !important;
	height: auto;
`;

//#endregion

//#region [Props]
type FilmwebArticleProps = {
	article: FilmwebTrailer | FilmwebNews;
}
//#endregion

//#region [Component]
function FilmwebArticle({ article }: FilmwebArticleProps) {

	let image: ICustomImage | undefined;
	switch (article._type) {
		case 'trailer':
			const trailer = (article as FilmwebTrailer);
			image = trailer.imageV2 ?? trailer.filmomtale?.mainImageV2 ?? trailer.filmomtale?.imagesV2?.[0];
			break;
		case 'filmnytt':
			image = (article as FilmwebNews).imageV2?.[0];
			break;
	}


	return (<SFilmwebArticle>
		<Link href={getUrl(article)} passHref legacyBehavior>
			<SArticleLink>
				<SImageContainer>
					{image && <SArticleImage isFilmweb image={image} width={450} heightRatio={185 / 370} />}
					{article._type === "trailer" && <TrailerIconSVG/>}
				</SImageContainer>
				<SArticleTitle>{article.title}</SArticleTitle>
			</SArticleLink>
		</Link>

	</SFilmwebArticle>);
}
//#endregion

//#region [Other]
function getUrl(article: FilmwebTrailer | FilmwebNews): string {
	let url: string;
	switch (article._type) {
		case 'trailer':
			url = `https://www.filmweb.no/trailere/#trailer${(article as FilmwebTrailer).videoId}`;
			break;
		case 'filmnytt':
			url = `https://www.filmweb.no/filmnytt/${(article as FilmwebNews).slug.current}`;
			break;
		default:
			url = "";
	}

	return url;
}
//#endregion