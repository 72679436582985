import { PortableTextBlock } from "@sanity/types";
import SSectionHeader from "components/framework/SSectionHeader";
import Icons, { CUSTOM_ICONS } from "components/images/Icons";
import RichText from "components/richtext/RichText";
import { INewsArticleWithIcon } from "lib/types/Frontpage";
import { getUrlForDocType, ISpecialShowArticle } from "lib/types/sanity";
import Link from "next/link";
import styled from "styled-components";

//#region [Styles]
const SArticleWithIconContainer = styled.section`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	flex-direction: column;
	margin-bottom: var(--spacer_size, 0);
	margin-top: calc(var(--spacer_size, 0) / 2);

	@media screen and (min-width: 641px) {
		flex-direction: row;
	}

	@media screen and (max-width: 640px) {
		padding: 10px 0;
		color: var(--color_section_text_mobile);
		background-color: var(--color_section_bg_mobile);
		a {
			color: var(--color_section_text_mobile);
		}

	}
`;
//#endregion

//#region [Props]

type ArticleWithIconContainerProps = {
	articleList?: (ISpecialShowArticle | INewsArticleWithIcon)[];
};
//#endregion

//#region [Component]
export default function ArticleWithIconContainer({ articleList }: ArticleWithIconContainerProps) {
	const artList = articleList?.filter(art => !!art?._id);
	if ((artList?.length ?? 0) === 0) {
		return null;
	}
	return <SArticleWithIconContainer>
		{artList?.map(article => <Article key={article._id} article={article} />)}
	</SArticleWithIconContainer>
}
//#endregion



//#region [Styles]
const SReadMore = styled.div`
	background: var(--color_articleiconlist_more_bg_mobile, #000);
	color: var(--color_articleiconlist_more_text_mobile, #fff);
	@media screen and (min-width: 641px) {
		background: var(--color_articleiconlist_more_bg, #000);
		color: var(--color_articleiconlist_more_text, #fff);
	}

	display: inline-block;
	padding: 0.3rem 0.6rem;
`;

const SArticle = styled.a`
	text-align: center;
	display: block;
	flex: 1 1 25%;

	margin-bottom: 50px;
	padding: 0 15px;
	&:hover {
		text-decoration: none;

		${SReadMore} {
			text-decoration: underline;
		}
	}

	@media screen and (min-width: 641px) {
		margin-bottom: 0;
		max-width: 30%;

		&:not(:first-child) {
			border-left: 3px solid var(--color_textlists_border, var(--color_primary));
		}
		&:not(:last-child) {
			border-right: 3px solid var(--color_textlists_border, var(--color_primary));
		}
	}

	svg, img {
		height: 70px;
		width: auto;
		display: block;
		margin: 0 auto 1rem auto;
	}


`;

const SArticleListTitle = styled(SSectionHeader)`
	--color_local_border_override: var(--color_articlewithicon_border);
`;



//#endregion

//#region [Props]
type ArticleProps = {
	article: (ISpecialShowArticle | INewsArticleWithIcon);
}
//#endregion

//#region [Component]
function Article({ article }: ArticleProps) {
	const url = getUrlForDocType(article._type, article.slug);
	if (!url) {
		return null;
	}
	return <Link href={url} passHref legacyBehavior>
		<SArticle>
			<>
				{article.customIcon && CUSTOM_ICONS[article.customIcon!]}
				{!article.customIcon && article.icon && Icons[article.icon!]}
				<SArticleListTitle as="h2">{article.title}</SArticleListTitle>
				{(article.frontpageText || article.ingress) && <RichText disableLinks value={(article.frontpageText ?? article.ingress) as PortableTextBlock[]} />}

				<SReadMore>Les mer</SReadMore>
			</>
		</SArticle>
	</Link>

}

//#endregion