import styled from "styled-components";
import { ITextList } from "lib/types/Frontpage";
import SSectionHeader from "components/framework/SSectionHeader";
import Link from "next/link";
import { getUrl } from "lib/types/sanity";
import { toRem } from "lib/client/fonts";

//#region [Styles]
const STextListContainer = styled.section`
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-bottom: var(--spacer_size, 0);

	@media screen and (min-width: 641px) {
		flex-direction: row;
		justify-content: center;
	}

	@media screen and (max-width: 640px) {
		padding: 10px 0;

		background-color: var(--color_section_bg_mobile, transparent);
		a, h1 {
			color: var(--color_section_text_mobile, var(--color_text, #000));
		}

	}
`;

type STextListLayoutProps = {
	listCount: number;
}

const STextListLayout = styled(TextList) <STextListLayoutProps>`

	@media screen and (min-width: 641px) {
		${props => `flex: 1 1 ${100 / props.listCount}%`}
	}
`

//#endregion

//#region [Props]
type TextListContainerProps = {
	textLists: ITextList[];
};
//#endregion

//#region [Component]
export default function TextListContainer({ textLists }: TextListContainerProps) {

	return (<STextListContainer>
		{textLists.map(list => <STextListLayout listCount={textLists.length} key={list._key} textList={list} />)}
	</STextListContainer>);
}
//#endregion

//#region [Styles]
const STextList = styled.section`
	text-align: center;


	@media screen and (min-width: 641px) {
		padding: 0 15px;
		flex: 1 1 25%;
		max-width: 30%;

		&:not(:first-child) {
			border-left: 3px solid var(--color_textlists_border, var(--color_primary));
		}
		&:not(:last-child) {
			border-right: 3px solid var(--color_textlists_border, var(--color_primary));
		}
	}

`;

const STextListTitle = styled(SSectionHeader)`
	--color_local_border_override: var(--color_textlists_border);
`;

const SList = styled.ol`
	list-style: none;
	padding: 0;
	margin: 0 0 2em 0;

	font-size: ${toRem(20)};
	li {
		margin-bottom: 1em;
	}
`;

//#endregion


//#region [Props]
type TextListProps = {
	textList: ITextList;
	className?: string;
};

//#endregion
//#region [Component]
function TextList({ textList, className }: TextListProps) {
	const listItems = textList?.listItems?.filter(li => !!(li?._id || li?._key));
	if ((listItems?.length ?? 0) === 0) {
		return null;
	}

	return <STextList className={className}>
		<STextListTitle>{textList.listTitle}</STextListTitle>
		<SList>
			{listItems.map(item => {
				const url = getUrl(item);
				if (!url) {
					console.warn("No url for", item);
					return null;
				}

				let title = item.title;
				if( 'titleOverride' in item && item.titleOverride) {
					title = item.titleOverride as string;
				}

				return (<li key={(item._id ?? item._key) as string}>
					<Link href={url}>
						{title}
					</Link>
				</li>);
			})}

		</SList>
	</STextList>
}
//#endregion