import { IButtonRowItem, getUrl } from "lib/types/sanity";
import Link from "next/link";
import styled from "styled-components";


//#region [Props]
type ButtonRowProps = {
	buttonrowItems?: IButtonRowItem[];
};
//#endregion

//#region [Styles]
const SButtonRow = styled.nav`
	margin: 1em auto;
	padding: 0 15px;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 30px;
	max-width: 1440px;

	a {
		flex: 0 1 calc(45% - 30px);
		text-align: center;
		background-color: var(--color_buttonrow_buttonbg, var(--color_menu_text, #000));
		color: var(--color_buttonrow_buttontext, var(--color_menu_bg, #fff));
		padding: 0.5rem 1rem;
		border-radius: 4px;

		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
		@media screen and (min-width: 641px){
			flex: 0 1 auto;
			min-width: 100px;
		}
	}

`;

//#endregion

//#region [Component]
export default function ButtonRow({ buttonrowItems }: ButtonRowProps) {
	if ((buttonrowItems?.length ?? 0) === 0) {
		return null;
	}
	return <SButtonRow>
		{buttonrowItems?.map(item => {
			const url = getUrl(item);
			if (!url) {
				return null;
			}
			return <Link key={item._key} href={url}>{item.title}</Link>;
		})}
	</SButtonRow>
}
//#endregion