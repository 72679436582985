import Settings from "lib/types/Settings";
import Link from "next/link";
import styled from "styled-components";

//#region [Styles]
const SLinkContainer = styled.div`
	text-align: center;
	margin-top: 1em;
`;

const SAllCultureShowsLink = styled.a`
	border: none;
	margin: 0;
	display: inline-block;
	text-align: center;
	padding: 0.3rem 0.6rem;
	background: var(--color_bgcolor_allshows_culture, var(--color_ticketbutton_bg, #000000));
	color: var(--color_textcolor_allshows_culture, var(--color_ticketbutton_text, #ffffff));
`;
//#endregion

//#region [Props]
type AllShowsLinkProps = {
    settings: Settings;
    showAllShowsFunction?: () => void;
    userOverriddenLinkText?: string;
};
//#endregion

//#region [Component]
export default function AllCultureShowsLink({ settings, showAllShowsFunction, userOverriddenLinkText }: AllShowsLinkProps) {
    return <SLinkContainer>
        {!showAllShowsFunction && <Link href="/kulturprogram" passHref legacyBehavior>
            <SAllCultureShowsLink>
                {userOverriddenLinkText ?? settings.text_allshows_culture ?? "Se hele kulturprogrammet"}
            </SAllCultureShowsLink>
        </Link>}
        {showAllShowsFunction && <SAllCultureShowsLink as="button" onClick={showAllShowsFunction}>{settings.text_allshows_culture ?? "Se hele kulturprogrammet"}</SAllCultureShowsLink>}
    </SLinkContainer>
}
//#endregion