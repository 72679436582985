import { getUrl, IMessageBanner, INewsArticle } from "lib/types/sanity";
import Link from "next/link";
import styled from "styled-components";

//#region [Styles]
const SMessageBanner = styled.div`
	background-color: var(--color_messagebanner_bg, var(--color_menu_text, #000));
	padding: 15px 0;
`;

const SBannerContent = styled.div`
	margin: 0 auto;
	max-width: 1480x;
	padding: 0 15px;
	text-align: center;

	a {
		color: var(--color_messagebanner_text, var(--color_menu_bg, #fff));
		text-decoration: underline;
		font-size: 1.2rem;
		@media screen and (min-width: 641px){
			text-decoration: none;
		}


		&:hover {
			text-decoration: underline;
		}
	}
`;

//#endregion

//#region [Props]
type MessageBannerProps = {
	banner?: IMessageBanner;
};
//#endregion

//#region [Component]
export default function MessageBanner({ banner }: MessageBannerProps) {
	if (!banner?.article) {
		return null;
	}
	const url = getUrl(banner.article);
	if (!url) {
		return null;
	}
	return <SMessageBanner>
		<SBannerContent><Link href={url}>{banner.overrideMessage ?? banner.article.title}</Link></SBannerContent>
	</SMessageBanner>;
}
//#endregion