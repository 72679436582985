import { useQuery } from "@tanstack/react-query";
import PosterShowsDisplay from "components/frontpage/cinemaprogram/PosterShowsDisplay";
import { getProgram } from "lib/client/movieinfo";
import IProgram from "lib/types/Program";
import Settings from "lib/types/Settings";
import { useState } from "react";

//#region [Props]
type ShowXShowsProps = {
	settings: Settings;
}
//#endregion

//#region [Component]
export default function ShowXShows({ settings }: ShowXShowsProps) {
	const [numToLoad, setNumToLoad] = useState((settings.program_xshows_numtoshow ?? 10));

	const { isLoading, isError, data, error, isFetching } = useQuery({
		queryKey: ["program-showx", numToLoad.toString()], // key
		queryFn: () => getProgram(undefined, undefined, true, numToLoad), // loader function
		refetchOnWindowFocus: false,
		refetchInterval: 600000, // refretch automatically every 10 minutes
		staleTime: 599999,
	} // options
	);
	return <PosterShowsDisplay heading="På kino" settings={settings} data={data as IProgram} error={error} isError={isError} isLoading={isLoading} isLoadingMore={isFetching} hideMoreLink={numToLoad === -1} showDate showAllShowsFunction={settings.program_shows_showall === "showall" ? () => setNumToLoad(-1) : undefined} />
}
//#endregion