import { useQuery } from "@tanstack/react-query";
import PosterShowsDisplay from "components/frontpage/cinemaprogram/PosterShowsDisplay";
import { getProgram } from "lib/client/movieinfo";
import IProgram from "lib/types/Program";
import Settings from "lib/types/Settings";

//#region [Props]
type ShowXShowsProps = {
	settings: Settings;
}
//#endregion

//#region [Component]
export default function ShowAllShows({ settings }: ShowXShowsProps) {
	const { isLoading, isError, data, error } = useQuery({
		queryKey: ["program-showall"], // key
		queryFn: () => getProgram(undefined, undefined, true), // loader function
		refetchOnWindowFocus: false,
		refetchInterval: 600000, // refretch automatically every 10 minutes
		staleTime: 599999,
	} // options
	);

	return <PosterShowsDisplay heading="På kino" settings={settings} data={data as IProgram} error={error} isError={isError} isLoading={isLoading} hideMoreLink showDate />
}
//#endregion