import { Filmomtale } from "@filmweb/sanity-types";
import { PortableTextBlock } from "@sanity/types";
import { CMSCroppedImage } from "components/images/Image";
import RichText from "components/richtext/RichText";
import { toRem } from "lib/client/fonts";
import { DocumentType_MOVIE, FwPakkeImageDocument, getUrl, IMovieArticle, INewsArticle, ISpecialShowArticle } from "lib/types/sanity";
import Link from "next/link";
import styled, { css } from "styled-components";

//#region [Styles]
const SArticleItem = styled.article`
	flex: 0 0 calc((100% - 60px) / 3 );

	a:hover {
		text-decoration: none;

		h1 {
			text-decoration: underline;
		}
	}

	@media screen and (max-width: 640px) {
		margin-bottom: 10px;
	}

`;

const SImageContainer = styled.div`
	aspect-ratio: 1 / 1;
	border-bottom: 7px solid var(--color_otherArts_border, var(--color_primary));

	@media screen and (max-width: 640px) {
		border-bottom-color: var(--color_otherArts_border_mob, var(--color_otherArts_border, var(--color_primary)));
	}

	&:empty {
		position: relative;
		background-color: #cacaca;

		&::after {
			content: "Bilde mangler";
			position: absolute;
			top: 50%;
			width: 100%;
			left: 0;
			text-align: center;
			transform: translateY(-50%);
		}
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}
`;

const STextContainer = styled.div`
	position: relative;
	padding: 2em 0 1em 0;
	background-color: var(--color_otherarts_bgcolor, #fff);

	@media screen and (max-width: 640px) {
		background-color: var(--color_otherarts_bgcolor_mob, var(--color_otherarts_bgcolor, #fff));
	}
`;

const SArticleTitle = styled.h1`
	font-size: ${toRem(20)};
	font-weight: normal;
	color: var(--color_otherarts_textcolor_title, #fff);
	background-color: var(--color_otherarts_bgcolor_title, var(--color_primary));

	@media screen and (max-width: 640px) {
		color: var(--color_otherarts_textcolor_title_mob, var(--color_otherarts_textcolor_title, #fff));
		background-color: var(--color_otherarts_bgcolor_title_mob, var(--color_otherarts_bgcolor_title, var(--color_primary)));
	}

	padding: 0.5rem 1rem;
	max-width: 80%;
	width: 100%;
	transform: translate(-50%, calc(-50% - 3.5px));
	position: absolute;
	left: 50%;
	top: 0;
	text-align: center;
	margin: 0;

`;

const ellipsis = css`
	font-size: 1.25rem;

	// The next 5 lines are required for a line-clamp
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ingressText = css`
	margin: 0 auto;
	line-height: 1.8;

	color: var(--color_otherarts_textcolor, #000000);
	text-align: center;
	max-width: 80%;
	width: 100%;
	font-weight: normal;
	font-style: normal;
	font-size: 1.25rem;
	font-family: "Open Sans",Helvetica,Arial,sans-serif;

	@media screen and (max-width: 640px) {
		color: var(--color_otherarts_textcolor_mob, var(--color_otherarts_textcolor, #000000));
	}
`;

const SIngress = styled(RichText)`
	${ellipsis}


	h1, h2, h3, h4, h5, h6, h5, strong, em, p {
		${ingressText}
	}
`;

const SOneliner = styled.p`
	${ellipsis}

	${ingressText}
`;

//#endregion

//#region [Props]
type ArticleItemProps = {
	article: (ISpecialShowArticle | INewsArticle | IMovieArticle);
	className?: string;
	film?: Filmomtale;
	allowTitleOverride?: boolean;
};
//#endregion


//#region [Component]
export default function ArticleItem({ article, className, film, allowTitleOverride }: ArticleItemProps) {
	const filmImage = film?.mainImageV2 ?? film?.imagesV2?.[0];

	let oneliner: string | undefined;
	let ingress: PortableTextBlock[] | undefined = article.frontpageText ?? article.ingress ?? film?.ingress;
	if (article._type === DocumentType_MOVIE) {
		oneliner = (article as IMovieArticle).oneliner;
	}

	let title: string = article.title;
	if (allowTitleOverride && 'titleOverride' in article && article.titleOverride) {
		title = article.titleOverride as string;
	}

	const url = getUrl(article);
	if (!url) {
		return null;
	}
	return <SArticleItem className={className}>
		<Link href={url}>
			<SImageContainer>
				{article.image && <CMSCroppedImage image={article.image as FwPakkeImageDocument} width={480} heightRatio={1} />}
				{!article.image && filmImage && <CMSCroppedImage image={filmImage} width={480} heightRatio={1} isFilmweb />}
			</SImageContainer>
			<STextContainer>
				<SArticleTitle>{title}</SArticleTitle>
				{oneliner && <SOneliner>{oneliner}</SOneliner>}
				{!oneliner && <SIngress value={ingress} disableLinks />}
			</STextContainer>
		</Link>
	</SArticleItem>;
}
//#endregion